var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);

        $emailForm.once('js-email-signup__form').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailError.html('');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;
              var alreadyPurchased = response.userinfo.previously_purchased;

              if (alreadySignedUp || alreadyPurchased) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }
            },
            onFailure: function (jsonRpcResponse) {
              var errorMessages = jsonRpcResponse.getError().data.messages;
              var messageText = '';

              for (i = 0; i < errorMessages.length; i++) {
                messageText += errorMessages[i].text + '<br />';
              }
              $emailError.html(messageText);
              $emailError.removeClass('hidden');
              $emailInput.addClass('error');
            }
          });
        });
      });
      $(document).on('email_signup:success_and_new', function () {
        site.template.get({
          name: 'email_signup_success_v1',
          callback: function (html) {
            generic.overlay.launch({
              content: html,
              height: 'auto',
              cssClass: 'qs-overlay-cbox',
              onComplete: function () {
                $.colorbox.resize();
              }
            });
          }
        });
      });
      $(document).on('email_signup:success_but_signed_up', function () {
        site.template.get({
          name: 'email_signup_already_signed_v1',
          callback: function (html) {
            generic.overlay.launch({
              content: html,
              height: 'auto',
              cssClass: 'qs-overlay-cbox',
              onComplete: function () {
                $.colorbox.resize();
              }
            });
          }
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context, settings) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery);
